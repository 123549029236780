<template>
  <div class="xiangmu_C">
    <div class="xiangmu_C_top">
      <div class="xiangmu_C_topl">
        <div class="xiangmu_C_toplimg">
          <img :src="xmall.userpic" alt="" />
        </div>
        <div class="xiangmu_C_topltext">
          <p>{{ xmall.title }}</p>
          <p>任务发布方：{{ xmall.username }}</p>
          <p>赏金分配：按竞标规则</p>
          <p>
            发布时间：{{ xmall.create_time }}
            <span>浏览人数：{{ xmall.browse }}人</span>
          </p>
        </div>
        <div class="xiangmu_C_topr_new">
          <div class="xiangmu_C_topr_newt">
            <div>{{ xmalltask_typedata[0].text }}</div>
          </div>
          <div class="xiangmu_C_topr_newb">
            <icon-svg icon-class="iconlook" />
            <span>{{ xmall.browse }}</span>
            <icon-svg icon-class="iconmessages" />
            <span>{{ xmall.plnum == null ? "0" : xmall.plnum }}</span>
            <icon-svg icon-class="icontotal" />
            <span>{{ count }}</span>
          </div>
        </div>
      </div>
      <div class="xiangmu_C_topr">
        <p>任务赏金</p>
        <p>￥{{ xmall.money }}</p>
        <div v-if="bmtype" @click="baomingbtn">{{ baomingtext }}</div>
        <el-checkbox v-if="bmtype" v-model="fuwuval">
          <a href="#/help?data=20&big=58">《服务协议》</a>
        </el-checkbox>
        <span v-if="bmzs" class="xiangmu_C_topr_zt">{{ baomingtext }}</span>
      </div>
    </div>
    <div class="xiangmu_C_liucheng">
      <p class="xiangmu_C_liucheng_t">参与流程</p>
      <ul class="xiangmu_C_xuqiu_div xiangmu_C_xuqiu_ul">
        <li class="xiangmu_C_xuqiu_ulimg">
          <img
            src="https://task.hozoin.cn/Works/20210111/sj-1401257224.png"
            alt=""
          />
          <img
            src="https://task.hozoin.cn/Works/20210111/sj-1401257224.png"
            alt=""
          />
          <p><span>1</span>报名</p>
        </li>
        <li class="xiangmu_C_xuqiu_ulimg_jt">
          <img :src="imgjiantou" alt="" />
        </li>
        <li class="xiangmu_C_xuqiu_ulimg">
          <img
            src="https://task.hozoin.cn/Works/20210111/sj-7408037226.png"
            alt=""
          />
          <img
            src="https://task.hozoin.cn/Works/20210111/sj-3197816843.png"
            alt=""
          />
          <p><span>2</span>调研分析与决策</p>
        </li>
        <li class="xiangmu_C_xuqiu_ulimg_jt">
          <img :src="imgjiantou" alt="" />
        </li>
        <li class="xiangmu_C_xuqiu_ulimg">
          <img
            src="https://task.hozoin.cn/Works/20210111/sj-4813856702.png"
            alt=""
          />
          <img
            src="https://task.hozoin.cn/Works/20210111/sj-9506526134.png"
            alt=""
          />
          <p><span>3</span>初步设计与提案</p>
        </li>
        <li class="xiangmu_C_xuqiu_ulimg_jt">
          <img :src="imgjiantou" alt="" />
        </li>
        <li class="xiangmu_C_xuqiu_ulimg">
          <img
            src="https://task.hozoin.cn/Works/20210111/sj-9710330606.png"
            alt=""
          />
          <img
            src="https://task.hozoin.cn/Works/20210111/sj-6415262460.png"
            alt=""
          />
          <p><span>4</span>方案深化与确立</p>
        </li>
        <li class="xiangmu_C_xuqiu_ulimg_jt">
          <img :src="imgjiantou" alt="" />
        </li>
        <li class="xiangmu_C_xuqiu_ulimg">
          <img
            src="https://task.hozoin.cn/Works/20210111/sj-0326761212.png"
            alt=""
          />
          <img
            src="https://task.hozoin.cn/Works/20210111/sj-8264858964.png"
            alt=""
          />
          <p><span>5</span>技术实现与扩初</p>
        </li>
        <li class="xiangmu_C_xuqiu_ulimg_jt">
          <img :src="imgjiantou" alt="" />
        </li>
        <li class="xiangmu_C_xuqiu_ulimg">
          <img
            src="https://task.hozoin.cn/Works/20210111/sj-9536900165.png"
            alt=""
          />
          <img
            src="https://task.hozoin.cn/Works/20210111/sj-1816580379.png"
            alt=""
          />
          <p><span>6</span>最终成果与交付</p>
        </li>
        <li class="xiangmu_C_xuqiu_ulimg_jt">
          <img :src="imgjiantou" alt="" />
        </li>
        <li class="xiangmu_C_xuqiu_ulimg">
          <img
            src="https://task.hozoin.cn/Works/20210111/sj-4900155332.png"
            alt=""
          />
          <img
            src="https://task.hozoin.cn/Works/20210111/sj-4410462127.png"
            alt=""
          />
          <p><span>7</span>项目结束</p>
        </li>
      </ul>
      <van-swipe
        :loop="false"
        class="swiper_xmios"
        :show-indicators="false"
        :width="100"
        :height="100"
      >
        <van-swipe-item>
          <div class="xiangmutopt_imgb">
            <img
              src="https://task.hozoin.cn/Works/20210111/sj-1401257224.png"
              alt=""
            />
            <div>报名</div>
          </div>
          <div class="xiangmutopt_imgs">
            <img
              src="https://task.hozoin.cn/Works/20210108/sj-3879790051.png"
              alt=""
            />
            <!-- 箭头 -->
          </div>
        </van-swipe-item>
        <van-swipe-item>
          <div class="xiangmutopt_imgb">
            <img
              src="https://task.hozoin.cn/Works/20210111/sj-3197816843.png"
              alt=""
            />
            <div>调研分析</div>
          </div>
          <div class="xiangmutopt_imgs">
            <img
              src="https://task.hozoin.cn/Works/20210108/sj-3879790051.png"
              alt=""
            />
            <!-- 箭头 -->
          </div>
        </van-swipe-item>
        <van-swipe-item>
          <div class="xiangmutopt_imgb">
            <img
              src="https://task.hozoin.cn/Works/20210111/sj-9506526134.png"
              alt=""
            />
            <div>初步设计与提案</div>
          </div>
          <div class="xiangmutopt_imgs">
            <img
              src="https://task.hozoin.cn/Works/20210108/sj-3879790051.png"
              alt=""
            />
            <!-- 箭头 -->
          </div>
        </van-swipe-item>
        <van-swipe-item>
          <div class="xiangmutopt_imgb">
            <img
              src="https://task.hozoin.cn/Works/20210111/sj-6415262460.png"
              alt=""
            />
            <div>方案深化与确立</div>
          </div>
          <div class="xiangmutopt_imgs">
            <img
              src="https://task.hozoin.cn/Works/20210108/sj-3879790051.png"
              alt=""
            />
            <!-- 箭头 -->
          </div>
        </van-swipe-item>
        <van-swipe-item>
          <div class="xiangmutopt_imgb">
            <img
              src="https://task.hozoin.cn/Works/20210111/sj-8264858964.png"
              alt=""
            />
            <div>技术实现与扩初</div>
          </div>
          <div class="xiangmutopt_imgs">
            <img
              src="https://task.hozoin.cn/Works/20210108/sj-3879790051.png"
              alt=""
            />
            <!-- 箭头 -->
          </div>
        </van-swipe-item>
        <van-swipe-item>
          <div class="xiangmutopt_imgb">
            <img
              src="https://task.hozoin.cn/Works/20210111/sj-1816580379.png"
              alt=""
            />
            <div>最终成果与交付</div>
          </div>
          <div class="xiangmutopt_imgs">
            <img
              src="https://task.hozoin.cn/Works/20210108/sj-3879790051.png"
              alt=""
            />
            <!-- 箭头 -->
          </div>
        </van-swipe-item>
        <van-swipe-item>
          <div class="xiangmutopt_imgb">
            <img
              src="https://task.hozoin.cn/Works/20210111/sj-4410462127.png"
              alt=""
            />
            <div>项目结束</div>
          </div>
          <div class="xiangmutopt_imgs"></div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="xiangmu_C_xuqiu">
      <p class="xiangmu_C_liucheng_t">项目概述</p>
      <div class="xiangmu_C_xuqiu_div" v-html="xmall.tasktext"></div>
    </div>
    <div class="xiangmu_C_xuqiu">
      <p class="xiangmu_C_liucheng_t">需求描述</p>
      <div class="xiangmu_C_xuqiu_div">
        <el-tabs v-model="xuqiuName" @tab-click="handleClick">
          <el-tab-pane
            v-for="(item, index) in dataall"
            :key="index"
            :label="item.one"
            :name="item.one"
          >
            <div class="Userhl_faxiangmutextbox">
              <div class="fxiangmu_top">
                <ul class="fxiangmu_topul">
                  <li
                    class="fxiangmu_topli"
                    v-for="(one, index) in item.two"
                    :key="index"
                    v-show="one.input_type != 3"
                  >
                    <div class="fxiangmu_toplic">{{ one.title }}</div>
                    <div class="fxiangmu_toplir">
                      <!-- /////////////// -->
                      <p v-if="one.input_type == 1 ? true : false">
                        {{ one.zhi }}
                      </p>
                      <!-- ////////////// -->
                      <p v-if="one.input_type == 2 ? true : false">
                        {{ one.zhi }}
                      </p>
                      <!-- /////////////////// -->
                      <ul
                        class="fxiangmu_topli0box"
                        v-if="one.input_type == 0 ? true : false"
                      >
                        <li v-for="(two, index) in one.three" :key="index">
                          <div class="fxiangmu_topli0boxlidiv1">
                            {{ two.title }}
                          </div>
                          <div class="fxiangmu_toplir_weituobox">
                            <span
                              v-for="(three, index) in two.zhi"
                              :key="index"
                              style="margin: 0 1rem"
                              v-show="two.input_type == '4'"
                              >{{ three }}</span
                            >
                            <span
                              v-for="(three, index) in two.zhi"
                              :key="index"
                              style="margin: 0 0"
                              v-show="two.input_type != '4'"
                              >{{ three }}</span
                            >
                          </div>
                        </li>
                      </ul>
                      <!-- /////////////////// -->
                      <!-- <div
                        class="fxiangmu_toplir_rwsbtnbox"
                        v-if="one.input_type == 3 ? true : false"
                      >
                        <div v-if="uploadziptype_rw">
                          <Uploadzip @uploadzip="uploadzipurlrenwushu" />
                        </div>
                        <div v-if="uploadziptype_f_rw">
                          <el-button disabled>上传完成！</el-button>
                          <el-button plain @click="uploadzipurl_again"
                            >重新上传附件</el-button
                          >
                          <input
                            type="hidden"
                            v-model="renwushuuploadzipurls"
                          />
                          <input
                            type="hidden"
                            value="嘿嘿"
                            v-model="one.zhi"
                            ref="upzipend"
                          />
                        </div>
                      </div> -->
                      <!-- /////////////////// -->
                      <div
                        class="fxiangmu_toplir_weituo"
                        v-show="one.input_type == 4 ? true : false"
                      >
                        <div
                          class="fxiangmu_toplir_weituobox"
                          v-for="(two, index) in one.three"
                          :key="index"
                        >
                          <span v-if="one.zhi == index">{{ two.title }}</span>
                        </div>
                      </div>
                      <!-- ///////////// -->
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="xiangmu_C_xuqiu">
      <p class="xiangmu_C_liucheng_t">交付标准</p>
      <div class="xiangmu_C_xuqiu_div">
        <ul class="xiangmu_C_xuqiu_divul">
          <li>
            <div>调研分析与决策</div>
            <div>调研分析报告</div>
          </li>
          <li>
            <div>初步设计与提案</div>
            <div>
              设计提案文件包含2D草图初稿提案、初步设计说明（设计主题、设计定位、设计风格等）
            </div>
          </li>
          <li>
            <div>方案深化与确立</div>
            <div>
              设计提案文件包含（设计说明细化、3D效果图、三视图、分析图、尺寸图、细节图等）报告
            </div>
          </li>
          <li>
            <div>技术实现与扩初</div>
            <div>
              设计提案文件包含（设计深化图纸、施工或制作工艺、材料选型选定、配色方案、施工方法、设计展开图、打样图等）
            </div>
          </li>
          <li>
            <div>最终成果与交付</div>
            <div>各阶段成果汇总、各成果源文件</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="xiangmu_C_xuqiu" v-if="taskfiletype">
      <p class="xiangmu_C_liucheng_t">下载附件</p>
      <div class="xiangmu_C_xuqiu_div">
        <el-button
          plain
          icon="el-icon-download"
          v-for="(item, index) in taskfile"
          :key="index"
        >
          <a :href="item.src" :download="item.name">{{ item.name }}</a>
        </el-button>
      </div>
    </div>
    <div class="xiangmu_C_baominguser">
      <p class="xiangmu_C_liucheng_t">报名设计师（{{ count }}）</p>
      <ul class="xiangmu_C_baominguserul">
        <li
          class="xiangmu_C_baominguseruli"
          v-for="(item, index) in userdata"
          :key="index"
        >
          <div class="xiangmu_C_baominguseruliimg">
            <img :src="item.userpic" alt="" />
          </div>
          <div class="xiangmu_C_baominguserulir">
            <p>{{ item.realname }}</p>
            <div class="xiangmu_C_baominguserulirt">
              <span
                v-for="(a, index) in item.skill"
                :key="index"
                v-show="index < 2"
                >{{ a == "" ? "暂无标签" : a }}</span
              >
            </div>
          </div>
        </li>
        <li class="xiangmu_C_baominguserulli_last">
          {{ count > 0 ? "共有 " + count + "名 设计师报名" : "暂无设计师报名" }}
        </li>
      </ul>
    </div>
    <div
      class="xiangmu_C_fan"
      v-if="
        userinfo != null &&
        xmjinzhantype == true &&
        zhanshitype == true &&
        weituo_tytype
      "
    >
      <div class="xiangmu_C_fan_t">
        <span>项目进展-{{ baomingtext }}</span>
        <span v-if="xmtaskpace > 2">
          <el-button type="text" @click="phoneqingqiu">{{
            phonetext
          }}</el-button>
        </span>
      </div>
      <!-- <div v-if="true" class="xiangmu_C_fan_tqy">
        <div class="xiangmu_C_fan_tqytitle">项目进展：</div>
        <ul class="xiangmu_C_fan_tqyul">
          <li>调研分析与决策</li>
          <li>初步设计与提案</li>
          <li>方案深化与确立</li>
          <li>技术实现与扩初</li>
          <li>最终成果与交付</li>
        </ul>
      </div> -->
      <!-- 企业详情展示 -->
      <el-tabs
        v-model="activeName"
        @tab-click="handleClick"
        v-if="userinfo != null && userinfo.groupid == 2"
      >
        <el-tab-pane label="调研分析与决策" name="onex">
          <div
            class="xiangmu_C_fan_b"
            v-for="(item, index) in xmsublist"
            :key="index"
          >
            <div class="xiangmu_C_fan_bl">
              <div class="xiangmu_C_baominguseruli">
                <div class="xiangmu_C_baominguseruliimg">
                  <img :src="item.userpic" alt="" />
                </div>
                <div class="xiangmu_C_baominguserulir">
                  <p>{{ item.realname }}</p>
                  <div class="xiangmu_C_baominguserulirt">
                    <span>包装设计</span>
                    <span>室内设计</span>
                  </div>
                </div>
              </div>
            </div>
            <ul
              class="xiangmu_C_fan_br xiangmu_C_fan_brqy"
              v-if="item.research"
            >
              <li
                class="xiangmu_C_fan_brli"
                v-for="(hh, index) in item.research"
                :key="index"
              >
                <div class="xiangmu_C_fan_brli_yuan">
                  <img
                    src="https://task.hozoin.cn/Works/20210111/sj-3197816843.png"
                    alt=""
                  />
                </div>
                <p class="xiangmu_C_fan_brli_t">调研分析与决策</p>
                <div v-if="hh.r_id" class="xiangmu_C_fan_brli_true">
                  <div class="xiangmu_C_fan_brli_tianxie">
                    {{ hh.create_time }}
                  </div>
                  <div class="xiangmu_C_fan_brli_shezhi">
                    <div @click="ck_diaoyan(hh.r_id, item.userid)">查看</div>
                  </div>
                </div>
                <div
                  v-if="
                    hh.checked == '2' || hh.checked == '4' || hh.checked == '5'
                  "
                  class="xiangmu_C_fan_brli_true"
                >
                  <div class="xiangmu_C_fan_brli_tianxie">
                    {{ hh.create_time }}
                  </div>
                  <p class="xiangmu_C_fan_brli_text">
                    {{
                      hh.checked == "2"
                        ? "已淘汰"
                        : hh.checked == "4"
                        ? "已入围"
                        : hh.report_state == "0"
                        ? "待审核"
                        : "已中标"
                    }}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </el-tab-pane>
        <el-tab-pane label="初步设计与提案" name="towx">
          <div
            class="xiangmu_C_fan_b"
            v-for="(item, index) in xmsublist"
            :key="index"
          >
            <div class="xiangmu_C_fan_bl">
              <div class="xiangmu_C_baominguseruli">
                <div class="xiangmu_C_baominguseruliimg">
                  <img :src="item.userpic" alt="" />
                </div>
                <div class="xiangmu_C_baominguserulir">
                  <p>{{ item.realname }}</p>
                  <div class="xiangmu_C_baominguserulirt">
                    <span>包装设计</span>
                    <span>室内设计</span>
                  </div>
                </div>
              </div>
            </div>
            <ul class="xiangmu_C_fan_br xiangmu_C_fan_brqy">
              <li
                class="xiangmu_C_fan_brli"
                v-for="(hh, index) in item.plan"
                :key="index"
              >
                <div class="xiangmu_C_fan_brli_yuan">
                  <img
                    src="https://task.hozoin.cn/Works/20210111/sj-9506526134.png"
                    alt=""
                  />
                </div>
                <p class="xiangmu_C_fan_brli_t">初步设计与提案</p>
                <div class="xiangmu_C_fan_brli_true" v-if="hh.checked == '0'">
                  <div class="xiangmu_C_fan_brli_tianxie">
                    {{ hh.create_time }}
                  </div>
                  <div class="xiangmu_C_fan_brli_shezhi">
                    <div @click="shenheuser(2, 1, item.userid, hh.task_type)">
                      {{ undertake == "0" ? "设置中标" : "通过" }}
                    </div>
                    <div
                      v-if="undertake == '0'"
                      @click="shenheuser(2, 2, item.userid)"
                    >
                      淘汰
                    </div>
                  </div>
                </div>
                <div v-if="hh.up_file" class="xiangmu_C_fan_brli_true">
                  <div class="xiangmu_C_fan_brli_tianxie">
                    {{ hh.create_time }}
                  </div>
                  <div class="xiangmu_C_fan_brli_shezhi">
                    <div>
                      <a :href="hh.up_file" :download="downloadName">下载</a>
                    </div>
                  </div>
                </div>
                <div
                  v-if="hh.checked == '1' || hh.checked == '2'"
                  class="xiangmu_C_fan_brli_true"
                >
                  <div class="xiangmu_C_fan_brli_tianxie">
                    {{ hh.create_time }}
                  </div>
                  <p class="xiangmu_C_fan_brli_text">
                    {{ hh.checked == "1" ? "已中标" : "已淘汰" }}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </el-tab-pane>
        <el-tab-pane label="方案深化与确立" name="threex">
          <div
            class="xiangmu_C_fan_b"
            v-for="(item, index) in xmsublist"
            :key="index"
          >
            <div class="xiangmu_C_fan_bl">
              <div class="xiangmu_C_baominguseruli">
                <div class="xiangmu_C_baominguseruliimg">
                  <img :src="item.userpic" alt="" />
                </div>
                <div class="xiangmu_C_baominguserulir">
                  <p>{{ item.username }}</p>
                  <div class="xiangmu_C_baominguserulirt">
                    <span>包装设计</span>
                    <span>室内设计</span>
                  </div>
                </div>
              </div>
            </div>
            <ul class="xiangmu_C_fan_br xiangmu_C_fan_brqy">
              <li class="xiangmu_C_fan_brli">
                <div class="xiangmu_C_fan_brli_yuan">
                  <img
                    src="https://task.hozoin.cn/Works/20210111/sj-6415262460.png"
                    alt=""
                  />
                </div>
                <p class="xiangmu_C_fan_brli_t">方案深化与确立</p>
                <div
                  v-if="program.checked == '0'"
                  class="xiangmu_C_fan_brli_true"
                >
                  <div class="xiangmu_C_fan_brli_shezhi">
                    <div @click="shenheuser(3, 1, item.userid)">同意</div>
                    <div v-if="jierutype != '1'">
                      <el-button
                        type="text"
                        @click="
                          jujuenum = 3;
                          qyjujue = true;
                          jujueuid = item.userid;
                        "
                        >拒绝</el-button
                      >
                    </div>
                  </div>
                </div>
                <div v-if="program.up_file" class="xiangmu_C_fan_brli_true">
                  <div class="xiangmu_C_fan_brli_tianxie">
                    {{ program.create_time }}
                  </div>
                  <div class="xiangmu_C_fan_brli_shezhi">
                    <div>
                      <a :href="program.up_file" :download="downloadName"
                        >下载</a
                      >
                    </div>
                  </div>
                </div>
                <div
                  v-if="program.checked == '1'"
                  class="xiangmu_C_fan_brli_true"
                >
                  <div class="xiangmu_C_fan_brli_tianxie">
                    {{ program.create_time }}
                  </div>
                  <p class="xiangmu_C_fan_brli_text">已同意</p>
                </div>
                <div
                  v-if="program.checked == 2 || jierutype == '1'"
                  class="xiangmu_C_fan_brli_true"
                >
                  <p
                    class="
                      xiangmu_C_fan_brli_text xiangmu_C_fan_brli_text_jujuejl
                    "
                  >
                    <span class="xiangmu_C_fan_brli_textjj">已拒绝记录</span>
                    <el-button
                      type="text"
                      @click="
                        dialogVisible = true;
                        task_typeid = program.task_type;
                      "
                      >查看</el-button
                    >
                  </p>
                  <p
                    class="xiangmu_C_fan_brli_text xiangmu_C_fan_brli_text_ptjr"
                    v-if="jierutype == '1'"
                  >
                    <el-button
                      type="text"
                      @click="ptjieruclick(program.task_type)"
                      >{{ ptjierutext }}</el-button
                    >
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </el-tab-pane>
        <el-tab-pane label="技术实现与扩初" name="fourx">
          <div
            class="xiangmu_C_fan_b"
            v-for="(item, index) in xmsublist"
            :key="index"
          >
            <div class="xiangmu_C_fan_bl">
              <div class="xiangmu_C_baominguseruli">
                <div class="xiangmu_C_baominguseruliimg">
                  <img :src="item.userpic" alt="" />
                </div>
                <div class="xiangmu_C_baominguserulir">
                  <p>{{ item.username }}</p>
                  <div class="xiangmu_C_baominguserulirt">
                    <span>包装设计</span>
                    <span>室内设计</span>
                  </div>
                </div>
              </div>
            </div>
            <ul class="xiangmu_C_fan_br xiangmu_C_fan_brqy">
              <li class="xiangmu_C_fan_brli">
                <div class="xiangmu_C_fan_brli_yuan">
                  <img
                    src="https://task.hozoin.cn/Works/20210111/sj-8264858964.png"
                    alt=""
                  />
                </div>
                <p class="xiangmu_C_fan_brli_t">技术实现与扩初</p>
                <div
                  v-if="technology.checked == '0'"
                  class="xiangmu_C_fan_brli_true"
                >
                  <div class="xiangmu_C_fan_brli_tianxie">
                    {{ technology.create_time }}
                  </div>
                  <div class="xiangmu_C_fan_brli_shezhi">
                    <div @click="shenheuser(4, 1, programid)">同意</div>
                    <div v-if="jierutype_jishu != '1'">
                      <el-button
                        type="text"
                        @click="
                          jujuenum = 4;
                          qyjujue = true;
                          jujueuid = programid;
                        "
                        >拒绝</el-button
                      >
                    </div>
                  </div>
                </div>
                <div v-if="technology.up_file" class="xiangmu_C_fan_brli_true">
                  <div class="xiangmu_C_fan_brli_tianxie">
                    {{ technology.create_time }}
                  </div>
                  <div class="xiangmu_C_fan_brli_shezhi">
                    <div>
                      <a :href="technology.up_file" :download="downloadName"
                        >下载</a
                      >
                    </div>
                  </div>
                </div>
                <div
                  v-if="technology.checked == '1'"
                  class="xiangmu_C_fan_brli_true"
                >
                  <div class="xiangmu_C_fan_brli_tianxie">
                    {{ technology.create_time }}
                  </div>
                  <p class="xiangmu_C_fan_brli_text">已同意</p>
                </div>
                <div
                  v-if="technology.checked == 2 || jierutype_jishu == '1'"
                  class="xiangmu_C_fan_brli_true"
                >
                  <p
                    class="
                      xiangmu_C_fan_brli_text xiangmu_C_fan_brli_text_jujuejl
                    "
                  >
                    <span class="xiangmu_C_fan_brli_textjj">已拒绝记录</span>
                    <el-button
                      type="text"
                      @click="
                        dialogVisible = true;
                        task_typeid = technology.task_type;
                      "
                      >查看</el-button
                    >
                  </p>
                  <p
                    class="xiangmu_C_fan_brli_text xiangmu_C_fan_brli_text_ptjr"
                    v-if="jierutype_jishu == '1'"
                  >
                    <el-button
                      type="text"
                      @click="ptjieruclick_jishu(technology.task_type)"
                      >{{ ptjierutext_jishu }}</el-button
                    >
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </el-tab-pane>
        <el-tab-pane label="最终成果与交付" name="fivex">
          <div
            class="xiangmu_C_fan_b"
            v-for="(item, index) in xmsublist"
            :key="index"
          >
            <div class="xiangmu_C_fan_bl">
              <div class="xiangmu_C_baominguseruli">
                <div class="xiangmu_C_baominguseruliimg">
                  <img :src="item.userpic" alt="" />
                </div>
                <div class="xiangmu_C_baominguserulir">
                  <p>{{ item.username }}</p>
                  <div class="xiangmu_C_baominguserulirt">
                    <span>包装设计</span>
                    <span>室内设计</span>
                  </div>
                </div>
              </div>
            </div>
            <ul class="xiangmu_C_fan_br xiangmu_C_fan_brqy">
              <li class="xiangmu_C_fan_brli">
                <div class="xiangmu_C_fan_brli_yuan">
                  <img
                    src="https://task.hozoin.cn/Works/20210111/sj-1816580379.png"
                    alt=""
                  />
                </div>
                <p class="xiangmu_C_fan_brli_t">最终成果与交付</p>
                <div
                  v-if="final.checked == '0'"
                  class="xiangmu_C_fan_brli_true"
                >
                  <div class="xiangmu_C_fan_brli_tianxie">
                    {{ final.create_time }}
                  </div>
                  <div class="xiangmu_C_fan_brli_shezhi">
                    <div @click="shenheuser(5, 1, programid)">同意</div>
                    <div v-if="jierutype_cg != '1'">
                      <el-button
                        type="text"
                        @click="
                          jujuenum = 5;
                          qyjujue = true;
                          jujueuid = programid;
                        "
                        >拒绝</el-button
                      >
                    </div>
                  </div>
                </div>
                <div v-if="final.up_file" class="xiangmu_C_fan_brli_true">
                  <div class="xiangmu_C_fan_brli_tianxie">
                    {{ final.create_time }}
                  </div>
                  <div class="xiangmu_C_fan_brli_shezhi">
                    <div>
                      <a :href="final.up_file" :download="downloadName">下载</a>
                    </div>
                  </div>
                </div>
                <div
                  v-if="final.checked == '1'"
                  class="xiangmu_C_fan_brli_true"
                >
                  <div class="xiangmu_C_fan_brli_tianxie">
                    {{ final.create_time }}
                  </div>
                  <p class="xiangmu_C_fan_brli_text">已同意</p>
                </div>
                <div
                  v-if="final.checked == '2' || jierutype_cg == '1'"
                  class="xiangmu_C_fan_brli_true"
                >
                  <div class="xiangmu_C_fan_brli_tianxie">
                    {{ final.create_time }}
                  </div>
                  <p
                    class="
                      xiangmu_C_fan_brli_text xiangmu_C_fan_brli_text_jujuejl
                    "
                  >
                    <span class="xiangmu_C_fan_brli_textjj">已拒绝记录</span>
                    <el-button
                      type="text"
                      @click="
                        dialogVisible = true;
                        task_typeid = final.task_type;
                      "
                      >查看</el-button
                    >
                  </p>
                  <p
                    class="xiangmu_C_fan_brli_text xiangmu_C_fan_brli_text_ptjr"
                    v-if="jierutype_cg == '1'"
                  >
                    <el-button
                      type="text"
                      @click="ptjieruclick_jishu(final.task_type)"
                      >{{ ptjierutext_cg }}</el-button
                    >
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </el-tab-pane>
      </el-tabs>
      <!-- 企业详情展示 -->
      <!-- 设计师详情展示 -->
      <div
        class="xiangmu_C_fan_b xiangmu_C_fan_b_user"
        v-if="userinfo != null && userinfo.groupid == 1"
      >
        <div class="xiangmu_C_fan_bl">
          <div class="xiangmu_C_baominguseruli">
            <div class="xiangmu_C_baominguseruliimg">
              <img :src="userinfo.userpic" alt="" />
            </div>
            <div class="xiangmu_C_baominguserulir">
              <p>{{ userinfo.username }}</p>
              <div class="xiangmu_C_baominguserulirt">
                <span>包装设计</span>
                <span>室内设计</span>
              </div>
            </div>
          </div>
        </div>
        <ul class="xiangmu_C_fan_br" style="width: 70rem">
          <li
            class="xiangmu_C_fan_brli"
            v-for="(aaa, index) in user_jz.research"
            :key="index"
            v-show="baomingtext != '我要报名'"
          >
            <div class="xiangmu_C_fan_brli_yuan">
              <img
                src="https://task.hozoin.cn/Works/20210111/sj-3197816843.png"
                alt=""
              />
            </div>
            <p class="xiangmu_C_fan_brli_t">调研分析与决策</p>
            <div v-if="aaa.checked == 88" class="xiangmu_C_fan_brli_false">
              <div class="xiangmu_C_fan_brli_tianxie" @click="Xiangmu_c_tx()">
                立即填写
              </div>
              <!-- <p class="xiangmu_C_fan_brli_text">通过后可获得1000元设计费</p> -->
            </div>
            <div
              v-if="aaa.checked == '1' || aaa.report_state == '0'"
              class="xiangmu_C_fan_brli_false"
            >
              <p class="xiangmu_C_fan_brli_text">等待企业确认</p>
            </div>
            <div
              v-if="
                aaa.checked == '2' ||
                aaa.checked == '4' ||
                (aaa.checked == '5' && aaa.report_state == '1')
              "
              class="xiangmu_C_fan_brli_true"
            >
              <div class="xiangmu_C_fan_brli_tianxie">
                {{ aaa.create_time }}
              </div>
              <p class="xiangmu_C_fan_brli_text" v-if="aaa.checked != '5'">
                {{ diaoyantype == "0" ? "已淘汰" : "已入围" }}
              </p>
              <p class="xiangmu_C_fan_brli_text" v-if="aaa.checked == '5'">
                已中标
              </p>
            </div>
          </li>
          <li
            class="xiangmu_C_fan_brli"
            v-for="(aaa, index) in user_jz.plan"
            :key="index"
            v-show="planzhanshi == 88"
          >
            <div class="xiangmu_C_fan_brli_yuan">
              <img
                src="https://task.hozoin.cn/Works/20210111/sj-9506526134.png"
                alt=""
              />
            </div>
            <p class="xiangmu_C_fan_brli_t">初步设计与提案</p>
            <div v-if="aaa.checked == 88" class="xiangmu_C_fan_brli_false">
              <div class="xiangmu_C_fan_brli_tianxie">
                上传
                <Uploadzip @uploadzip="uploadzipurl" />
              </div>
              <p class="xiangmu_C_fan_brli_text">（建议PDF格式）</p>
              <!-- <p class="xiangmu_C_fan_brli_text">通过后可获得1000元设计费</p> -->
            </div>
            <div v-if="aaa.checked == '0'" class="xiangmu_C_fan_brli_false">
              <p class="xiangmu_C_fan_brli_text">待企业确认</p>
            </div>
            <div
              v-if="aaa.checked == '1' || aaa.checked == '2'"
              class="xiangmu_C_fan_brli_true"
            >
              <div class="xiangmu_C_fan_brli_tianxie">
                {{ aaa.create_time }}
              </div>
              <p class="xiangmu_C_fan_brli_text">
                {{ aaa.checked == "1" ? "已中标" : "已淘汰" }}
              </p>
            </div>
          </li>
          <li class="xiangmu_C_fan_brli" v-if="programzhanshi == 88">
            <div class="xiangmu_C_fan_brli_yuan">
              <img
                src="https://task.hozoin.cn/Works/20210111/sj-6415262460.png"
                alt=""
              />
            </div>
            <p class="xiangmu_C_fan_brli_t">方案深化与确立</p>
            <div
              v-if="
                programzhanshi == 88 ||
                (program.checked == 2 &&
                  xmtaskpace != '6' &&
                  program.checked != 0)
              "
              class="xiangmu_C_fan_brli_false"
            >
              <div
                class="xiangmu_C_fan_brli_tianxie"
                v-if="program.checked != 0 && program.checked != 1"
              >
                {{ program.checked == "2" ? "重新上传" : "上传" }}
                <Uploadzip @uploadzip="uploadzipurl_2" />
              </div>
              <!-- <p class="xiangmu_C_fan_brli_text">通过后可获得1000元设计费</p> -->
            </div>
            <div v-if="program.checked == 0" class="xiangmu_C_fan_brli_false">
              <p class="xiangmu_C_fan_brli_text">待企业确认</p>
            </div>
            <div v-if="program.checked == '1'" class="xiangmu_C_fan_brli_true">
              <div class="xiangmu_C_fan_brli_tianxie">
                {{ program.create_time }}
              </div>
              <p class="xiangmu_C_fan_brli_text">已同意</p>
            </div>
            <div v-if="program.checked == 2" class="xiangmu_C_fan_brli_true">
              <div class="xiangmu_C_fan_brli_tianxie">
                {{ program.create_time }}
              </div>
              <p
                class="xiangmu_C_fan_brli_text xiangmu_C_fan_brli_text_jujuejl"
              >
                <span class="xiangmu_C_fan_brli_textjj">已拒绝记录</span>
                <el-button
                  type="text"
                  @click="
                    task_typeid = program.task_type;
                    dialogVisible = true;
                    programid = userinfo.userid;
                  "
                  >查看</el-button
                >
              </p>
            </div>
          </li>
          <li class="xiangmu_C_fan_brli" v-if="program.checked == '1'">
            <div class="xiangmu_C_fan_brli_yuan">
              <img
                src="https://task.hozoin.cn/Works/20210111/sj-8264858964.png"
                alt=""
              />
            </div>
            <p class="xiangmu_C_fan_brli_t">技术实现与扩初</p>
            <div
              v-if="
                technology.checked == 88 ||
                (technology.checked == 2 && xmtaskpace != '6')
              "
              class="xiangmu_C_fan_brli_false"
            >
              <div
                class="xiangmu_C_fan_brli_tianxie"
                v-if="technology.checked != '0'"
              >
                {{ technology.checked == "2" ? "重新上传" : "上传" }}
                <Uploadzip @uploadzip="uploadzipurl_3" />
              </div>
              <!-- <p class="xiangmu_C_fan_brli_text">通过后可获得1000元设计费</p> -->
            </div>
            <div
              v-if="technology.checked == '0'"
              class="xiangmu_C_fan_brli_false"
            >
              <p class="xiangmu_C_fan_brli_text">待企业确认</p>
            </div>
            <div
              v-if="technology.checked == '1'"
              class="xiangmu_C_fan_brli_true"
            >
              <div class="xiangmu_C_fan_brli_tianxie">
                {{ technology.create_time }}
              </div>
              <p class="xiangmu_C_fan_brli_text">已同意</p>
            </div>
            <div v-if="technology.checked == 2" class="xiangmu_C_fan_brli_true">
              <div class="xiangmu_C_fan_brli_tianxie">
                {{ technology.create_time }}
              </div>
              <p
                class="xiangmu_C_fan_brli_text xiangmu_C_fan_brli_text_jujuejl"
              >
                <span class="xiangmu_C_fan_brli_textjj">已拒绝记录</span>
                <el-button
                  type="text"
                  @click="
                    dialogVisible = true;
                    task_typeid = technology.task_type;
                    programid = userinfo.userid;
                  "
                  >查看</el-button
                >
              </p>
            </div>
          </li>
          <li class="xiangmu_C_fan_brli" v-if="technology.checked == '1'">
            <div class="xiangmu_C_fan_brli_yuan">
              <img
                src="https://task.hozoin.cn/Works/20210111/sj-1816580379.png"
                alt=""
              />
            </div>
            <p class="xiangmu_C_fan_brli_t">最终成果与交付</p>
            <div
              v-if="
                finalzhanshi == '88' ||
                (final.checked == 2 && xmtaskpace != '6')
              "
              class="xiangmu_C_fan_brli_false"
            >
              <div
                class="xiangmu_C_fan_brli_tianxie"
                v-if="final.checked != '0'"
              >
                {{ final.checked == "2" ? "重新上传" : "上传" }}
                <Uploadzip @uploadzip="uploadzipurl_4" />
              </div>
              <!-- <p class="xiangmu_C_fan_brli_text">通过后可获得1000元设计费</p> -->
            </div>
            <div class="xiangmu_C_fan_brli_false" v-if="final.checked == '0'">
              <p class="xiangmu_C_fan_brli_text">待企业确认</p>
            </div>
            <div class="xiangmu_C_fan_brli_true" v-if="final.checked == '1'">
              <div class="xiangmu_C_fan_brli_tianxie">
                {{ final.create_time }}
              </div>
              <p class="xiangmu_C_fan_brli_text">已同意</p>
            </div>
            <div class="xiangmu_C_fan_brli_true" v-if="final.checked == '2'">
              <div class="xiangmu_C_fan_brli_tianxie">
                {{ final.create_time }}
              </div>
              <p
                class="xiangmu_C_fan_brli_text xiangmu_C_fan_brli_text_jujuejl"
              >
                <span class="xiangmu_C_fan_brli_textjj">已拒绝记录</span>
                <el-button
                  type="text"
                  @click="
                    dialogVisible = true;
                    task_typeid = final.task_type;
                    programid = userinfo.userid;
                  "
                  >查看</el-button
                >
              </p>
            </div>
          </li>
        </ul>
      </div>
      <!-- 设计师详情展示 -->
      <!-- 星级模板 -->
      <Sjxingji v-if="xingjitype" :all="Sjxingjiall" />
      <!-- 星级模板 -->
    </div>
    <Sjpinglun :all="pinglunall" />
    <zan :all="zanall" />
    <!-- 拒绝消息提示 -->
    <el-dialog
      title="驳回理由"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <!-- ///// -->
      <el-timeline :reverse="time_shunxu">
        <el-timeline-item
          v-for="(activity, index) in activities"
          :key="index"
          :timestamp="activity.audit_time"
          v-show="activity.reason"
        >
          {{ activity.reason }}
        </el-timeline-item>
      </el-timeline>
      <!-- ////////// -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 拒绝消息提示 -->
    <!-- 平台介入进度提示 -->
    <el-dialog
      title="平台介入进度"
      :visible.sync="jierujindu"
      width="30%"
      :before-close="handleClose"
    >
      <!-- ///// -->
      <el-timeline :reverse="time_shunxu">
        <el-timeline-item
          v-for="(activity, index) in jierujinduall"
          :key="index"
          :timestamp="activity.create_time"
        >
          {{ activity.cause }}
        </el-timeline-item>
      </el-timeline>
      <!-- ////////// -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="jierujindu = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 平台介入进度提示 -->
    <!-- 申请平台介入 -->
    <el-dialog
      title="申请平台介入"
      :visible.sync="ptjieru"
      width="30%"
      :before-close="handleClose"
    >
      <!-- ///// -->
      <p class="ptjieru_p">
        <span>申请理由</span>
        <el-input
          type="textarea"
          placeholder="请输入申请理由"
          v-model="jieruliyou"
        >
        </el-input>
      </p>
      <!-- ////////// -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="ptjierubtn">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 申请平台介入 -->
    <!-- 联系方式 -->
    <el-dialog
      title="联系方式"
      :visible.sync="qiyephone"
      width="30%"
      :before-close="handleClose"
    >
      <!-- ///// -->
      <p class="ptjieru_p">
        <span>QQ</span>
        <span>{{
          phonedata.oicq == "" || phonedata.oicq == null
            ? "暂无"
            : phonedata.oicq
        }}</span>
      </p>
      <p class="ptjieru_p">
        <span>微信</span>
        <span>{{
          phonedata.wechat == "" || phonedata.wechat == null
            ? "暂无"
            : phonedata.wechat
        }}</span>
      </p>
      <p class="ptjieru_p">
        <span>手机</span>
        <span>{{
          phonedata.mobile == "" || phonedata.mobile == null
            ? "暂无"
            : phonedata.mobile
        }}</span>
      </p>
      <p class="ptjieru_p">
        <span>邮箱</span>
        <span>{{
          phonedata.email == "" || phonedata.email == null
            ? "暂无"
            : phonedata.email
        }}</span>
      </p>
      <!-- ////////// -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="qiyephone = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 联系方式 -->
    <!-- 拒绝提案 -->
    <el-dialog
      title="拒绝提案"
      :visible.sync="qyjujue"
      width="30%"
      :before-close="handleClose"
    >
      <!-- ///// -->
      <p class="ptjieru_p">
        <span>拒绝理由</span>
        <el-input
          type="textarea"
          placeholder="请输入拒绝提案理由"
          v-model="jujuetext"
        >
        </el-input>
      </p>
      <!-- ////////// -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="shenheuser(jujuenum, 2, jujueuid)"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 拒绝提案 -->
  </div>
</template>
<script>
import zan from "../components/zan";
import Uploadzip from "../components/Uploadzip";
import Sjpinglun from "../components/Sjpinglun";
import Sjxingji from "../components/Sjxingji";
export default {
  components: {
    Uploadzip,
    zan,
    Sjpinglun,
    Sjxingji,
  },
  data() {
    return {
      undertake: "0", //0非委托  1委托
      weituo_tytype: true,
      zhanshitype: false,
      phonedata: {},
      faxiangmuid: "",
      phonetext: "",
      xingjitype: false,
      taskfile: [],
      xmjinzhantype: true,
      bmzs: false,
      pinglunall: {
        classid: "1",
        id: "",
      },
      Sjxingjiall: {
        task_id: "",
        userid: "",
        taskmost: "",
        taskspeed: "",
        third: "",
        fourth: "",
      },
      xuqiuName: "",
      zanall: {
        classid: "1",
        id: "0",
      },
      planzhanshi: "0",
      finalzhanshi: "0",
      programzhanshi: "0",
      uname_l: "", //企业查看报名设计师名字
      upic_l: "", //企业查看报名设计师头像
      xmtaskpace: "", //项目进展状态
      jierutype_cg: "",
      jierujinduall: [
        {
          create_time: "",
          cause: "正在处理中",
        },
      ], //平台介入进度数据
      jierujindu: false, //平台介入进度 显示
      ptjierutext: "平台介入",
      ptjierutext_jishu: "平台介入",
      ptjierutext_cg: "平台介入",
      jieruliyou: "", //平台介入理由
      uploadzipurlnum: 0,
      jierutype: "0", //
      jierutype_jishu: "", //技术实现查看驳回消息
      task_typeid: "", //企业查看拒绝消息的步骤id
      jujueuid: "", //拒绝设计师id
      jujuenum: "", //项目哪一步拒绝
      jujuetext: "", //拒绝理由
      uploadzipurls: "",
      u_checked: "", //设计师报名状态
      dataall: [],
      user_jz: {},
      task_id: "",
      activeName: "onex",
      time_shunxu: true,
      baomingtext: "",
      count: 0,
      bmtype: false,
      userinfo: {}, //////////////////////////////我的资料
      activities: [],
      downloadName: "附件",
      dialogVisible: false,
      ptjieru: false,
      qiyephone: false,
      qyjujue: false,
      ptjieru_name: "",
      ptjieru_ly: "",
      img: "https://task.hozoin.cn/Works/20201104/sj-4369072618.png",
      imgjiantou:
        "https://task.hozoin.cn/Works/20210108/sj-3879790051.png",
      fuwuval: false,
      activenum: 7,
      xiangmutopt_timg0: true,
      xiangmutopt_timg0_f: false,
      xiangmutopt_timg1: true,
      xiangmutopt_timg1_f: false,
      xiangmutopt_timg2: true,
      xiangmutopt_timg2_f: false,
      xiangmutopt_timg3: true,
      xiangmutopt_timg3_f: false,
      xiangmutopt_timg4: true,
      xiangmutopt_timg4_f: false,
      xiangmutopt_timg5: true,
      xiangmutopt_timg5_f: false,
      xiangmutopt_timg6: true,
      xiangmutopt_timg6_f: false,
      imgall: {
        img0: "https://www.shijianpro.com/Public/images/task/item_process_1_1.png",
        img0_f:
          "https://www.shijianpro.com/Public/images/task/item_process_1_0.png",
        img1: "https://www.shijianpro.com/Public/images/task/item_process_1_1.png",
        img1_f:
          "https://www.shijianpro.com/Public/images/task/item_process_1_0.png",
        img2: "https://www.shijianpro.com/Public/images/task/item_process_2_1.png",
        img2_f:
          "https://www.shijianpro.com/Public/images/task/item_process_2_0.png",
        img3: "https://www.shijianpro.com/Public/images/task/item_process_3_1.png",
        img3_f:
          "https://www.shijianpro.com/Public/images/task/item_process_3_0.png",
        img4: "https://www.shijianpro.com/Public/images/task/item_process_4_1.png",
        img4_f:
          "https://www.shijianpro.com/Public/images/task/item_process_4_0.png",
        img5: "https://www.shijianpro.com/Public/images/task/item_process_5_1.png",
        img5_f:
          "https://www.shijianpro.com/Public/images/task/item_process_5_0.png",
        img6: "https://www.shijianpro.com/Public/images/task/item_process_7_1.png",
        img6_f:
          "https://www.shijianpro.com/Public/images/task/item_process_7_0.png",
      },
      userdata: [],
      xmall: {},
      user_info_iostanbqall: [
        {
          text: "规划设计",
          num: "1",
          classred: "",
          ft: "0",
        },
        {
          text: "景观设计",
          num: "2",
          classred: "",
          ft: "0",
        },
        {
          text: "建筑设计",
          num: "3",
          classred: "",
          ft: "0",
        },
        {
          text: "室内设计",
          num: "4",
          classred: "",
          ft: "0",
        },
        {
          text: "软装设计",
          num: "5",
          classred: "",
          ft: "0",
        },
        {
          text: "服装设计",
          num: "6",
          classred: "",
          ft: "0",
        },
        {
          text: "家具设计",
          num: "7",
          classred: "",
          ft: "0",
        },
        {
          text: "交通工具设计",
          num: "8",
          classred: "",
          ft: "0",
        },
        {
          text: "轻工产品设计",
          num: "9",
          classred: "",
          ft: "0",
        },
        {
          text: "机械设备设计",
          num: "10",
          classred: "",
          ft: "0",
        },
        {
          text: "广告设计",
          num: "11",
          classred: "",
          ft: "0",
        },
        {
          text: "包装设计",
          num: "12",
          classred: "",
          ft: "0",
        },
        {
          text: "图书设计",
          num: "13",
          classred: "",
          ft: "0",
        },
        {
          text: "展陈设计",
          num: "14",
          classred: "",
          ft: "0",
        },
        {
          text: "品牌标志设计",
          num: "15",
          classred: "",
          ft: "0",
        },
      ],
      xmsublist: [],
      chubuuser: false, //初步设计展示
      planall: {}, //初步设计数据
      diaoyantype: "", //调研淘汰 / 初步淘汰
      program: {}, //方案深化数据
      programid: "", //\\\\\\\\\\\\\\\\\\\\中标者id
      technology: {}, //技术实现与扩初
      final: {}, //最终方案
      taskfiletype: false,
      signUseridall: [],
      checked_wttype_qy: "0",
      xmalltask_typedata:[{text:"项目类型"}],
    };
  },
  created() {
    var that = this;
    this.task_id = that.$route.query.data;
    var id = that.$route.query.data;
    this.Sjxingjiall.task_id = id;
    that.zanall.id = id;
    that.pinglunall.id = id;
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.tokentimeyz();
    // this.userinfo.groupid = 1;
    //项目详情接口
    that
      .$axios({
        url: "/api/getProjectDetail",
        method: "post",
        data: {
          id,
        },
      })
      .then((res) => {
        that.undertake = res.data.result.undertake;
        document.title = res.data.result.title;
        this.fenxiang(
          res.data.result.title,
          res.data.result.title,
          res.data.result.titlepic
        );
        if (res.data.result.taskfile != "") {
          that.taskfiletype = true;
          that.taskfile = JSON.parse(res.data.result.taskfile);
        } else {
          that.taskfiletype = false;
        }
        that.xmall = res.data.result;
        //项目类型
        that.xmalltask_typedata = that.user_info_iostanbqall.filter((re) => {
            return re.num == res.data.result.task_type
        });
        that.xmtaskpace = res.data.result.taskpace;
        that.faxiangmuid = res.data.result.userid;
        //项目任务计划书
        that
          .$axios({
            url: "/api/getTaskDemandFind",
            method: "post",
            data: {
              id: res.data.result.demand_id,
            },
          })
          .then((res) => {
            console.log(res);
            if (res.data.code == 1) {
              that.dataall = JSON.parse(res.data.result.question);
              that.xuqiuName = that.dataall[0].one;
              console.log(that.dataall);
            } else {
              console.log("请求失败");
            }
          });
        //项目任务计划书
        //据身份展示页面内容
        if (that.userinfo != null) {
          that.signUseridall = (
            res.data.result.signUserid +
            "," +
            res.data.result.userid
          ).split(",");
          that.zhanshitype = that.signUseridall.includes(
            "" + that.userinfo.userid + ""
          );
          if (that.userinfo.groupid == "2") {
            that.phonetext = "获取设计师联系方式";
            that.bmtype = false;
            that.bmzs = true;
            ////////////企业展示项目状态
            that.xiangmujinzhantext();
          } else if (that.userinfo.groupid == "1") {
            that.phonetext = "获取企业联系方式";
            ////////////设计师报名状态
            that
              .$axios({
                headers: { "X-CSRF-TOKEN": that.userinfo.token },
                url: "/api/getFindSetUp",
                method: "post",

                data: {
                  task_id: that.task_id,
                },
              })
              .then((res) => {
                if (res.data.code == 1) {
                  that.bmtype = false;
                  that.bmzs = true;
                  that.u_checked = res.data.result.checked;
                  if (res.data.result.checked == 1) {
                    that.baomingtext = "报名已同意";
                  } else if (res.data.result.checked == 2) {
                    that.baomingtext = "被淘汰";
                  } else if (res.data.result.checked == 3) {
                    that.baomingtext = "已取消报名";
                  } else if (res.data.result.checked == 4) {
                    that.baomingtext = "已入围";
                  } else if (res.data.result.checked == 5) {
                    that.baomingtext = "已中标";
                  } else if (res.data.result.checked == 6) {
                    that.baomingtext = "待同意委托";
                    that.weituo_tytype = false;
                  }
                } else {
                  that.xmjinzhantype = false;
                  if (that.xmtaskpace == 0) {
                    that.baomingtext = "我要报名";
                    that.bmtype = true;
                    that.bmzs = false;
                  } else if (that.xmtaskpace == 1) {
                    that.baomingtext = "我要报名";
                    that.bmtype = true;
                    that.bmzs = false;
                  } else if (that.xmtaskpace == 2) {
                    that.baomingtext = "初步设计与提案";
                    that.bmtype = false;
                    that.bmzs = true;
                  } else if (that.xmtaskpace == 3) {
                    that.baomingtext = "方案深化与确立";
                    that.bmtype = false;
                    that.bmzs = true;
                  } else if (that.xmtaskpace == 4) {
                    that.baomingtext = "技术实现与扩初";
                    that.bmtype = false;
                    that.bmzs = true;
                  } else if (that.xmtaskpace == 5) {
                    that.baomingtext = "最终成果与交付";
                    that.bmtype = false;
                    that.bmzs = true;
                  } else if (that.xmtaskpace == 6) {
                    that.baomingtext = "项目结束";
                    that.bmtype = false;
                    that.bmzs = true;
                  }
                  if (that.undertake == "1") {
                    that.bmtype = false;
                    that.bmzs = true;
                    that.baomingtext = "直接委托项目";
                  }
                }
              });
            ////////////设计师报名状态
            //////设计师查看方案进度状态
            that
              .$axios({
                headers: { "X-CSRF-TOKEN": that.userinfo.token },
                url: "/api/getTaskPlanState",
                method: "post",
                data: {
                  task_id: that.task_id,
                },
              })
              .then((res) => {
                if (res.data.code == 1) {
                  that.user_jz = res.data.result;
                  ////////
                  var addall = {
                    checked: 88,
                  };
                  if (that.user_jz.research == "") {
                    //调研报告
                    that.user_jz.research.push(addall);
                  }

                  if (that.user_jz.plan.length > 0) {
                    that.planzhanshi = 88;
                  }
                  if (that.user_jz.plan == "") {
                    //初步
                    that.user_jz.plan.push(addall);
                    var len = that.user_jz.research.length - 1;
                    if (
                      that.user_jz.research[len].checked == 4 ||
                      that.user_jz.research[len].checked == 5
                    ) {
                      if (that.user_jz.research[len].report_state == "0") {
                        that.planzhanshi = "0";
                      } else {
                        that.planzhanshi = 88;
                      }
                    }
                  }

                  if (that.user_jz.program == "") {
                    //方案深化与确立
                    that.user_jz.program.push(addall);
                    if (that.user_jz.plan[0].checked == 1) {
                      that.programzhanshi = 88;
                    }
                  }

                  if (that.user_jz.technology == "") {
                    //技术扩充
                    that.user_jz.technology.push(addall);
                  }
                  if (that.user_jz.final == "") {
                    //最终方案
                    that.user_jz.final.push(addall);
                    var len = that.user_jz.technology.length - 1;
                    if (that.user_jz.technology[len].checked == 1) {
                      that.finalzhanshi = 88;
                    }
                  }

                  /////////////
                  if (that.user_jz.program.length > 0) {
                    //方案深化
                    //把最后一次的企业查看状态值  带到数据
                    var pr = that.user_jz.plan.length - 1;
                    if (that.user_jz.plan[pr].checked == 1) {
                      that.programzhanshi = 88;
                    }
                    var legnum = Number(that.user_jz.program.length) - 1;
                    that.program = that.user_jz.program[legnum];
                  } else {
                    that.program = that.user_jz.program[0];
                  }
                  ///////
                  if (that.user_jz.technology.length > 0) {
                    //技术实现
                    var legnum = Number(that.user_jz.technology.length) - 1;
                    that.technology = that.user_jz.technology[legnum];
                  } else {
                    that.technology = that.user_jz.technology[0];
                  }
                  if (that.user_jz.final.length > 0) {
                    //最终成果
                    var legnum = Number(that.user_jz.final.length) - 1;
                    that.final = that.user_jz.final[legnum];
                  } else {
                    that.final = that.user_jz.final[0];
                  }

                  /////////
                  if (
                    that.user_jz.research[0].checked == "4" ||
                    that.user_jz.research[0].checked == "2" ||
                    that.user_jz.research[0].checked == "5"
                  ) {
                    that.chubuuser = true;
                    if (that.user_jz.preliminary != "") {
                      //初步设计淘汰
                      that.diaoyantype = 1;
                    } else {
                      //调研分析淘汰
                      that.diaoyantype = 0;
                    }
                  }
                  ///////
                } else {
                  this.$message({
                    message: res.data.messages,
                    duration: 1000,
                  });
                }
              });
            //////设计师查看方案进度状态
          }
          this.xmjinzhan(1);
        } else {
          that.xiangmujinzhantext();
          this.bmtype = false;
          this.bmzs = true;
          this.zhanshitype = false;
        }
        //据身份展示页面内容
      });
    /////////////项目详情接口
    that.baomingshejishi(id);
  },
  methods: {
    handleClose(done) {
      done();
    },
    Xiangmu_c_tx() {
      var that = this;
      this.$router.push({
        path: "/Xiangmu_c_tx",
        query: { data: this.task_id, xmalltitle: that.xmall.title },
      });
    },
    down(zip) {
      this.$refs.zip.click();
    },
    baomingbtn() {
      var that = this;
      if (this.fuwuval) {
        if (this.userinfo != null) {
          that
            .$axios({
              headers: { "X-CSRF-TOKEN": that.userinfo.token },
              url: "/api/project/getSetUp",
              method: "post",

              data: {
                userid: that.userinfo.userid,
                task_id: that.task_id,
              },
            })
            .then((res) => {
              if (res.data.code == 1) {
                this.$message({
                  message: res.data.messages,
                  duration: 1000,
                });
                location.reload();
              } else {
                this.$message({
                  message: res.data.messages,
                  duration: 1000,
                });
              }
            });
        } else {
          this.$router.push({ path: "/Sjlogin" });
        }
      } else {
        this.$message({
          message: "请同意报名协议！",
          duration: 1000,
        });
      }
    },
    handleClick(index) {
      var indexid = Number(index.index) + 1;
      this.xmsublist = [];
      this.xmjinzhan(indexid);
    },
    xmjinzhan(taskpace) {
      var that = this;
      that
        .$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/getDesignerTaskInfo",
          method: "post",
          data: {
            task_id: that.task_id,
            taskpace,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            that.xmsublist = res.data.result;
            if (taskpace == 3) {
              if (that.xmsublist[0].program) {
                //方案深化
                that.programid = that.xmsublist[0].userid;
                if (that.xmsublist[0].program.length > 0) {
                  //把最后一次的企业查看状态值  带到数据
                  var legnum = Number(that.xmsublist[0].program.length) - 1;
                  that.program = that.xmsublist[0].program[legnum];
                  if (legnum == 2) {
                    that.jierutype = "1";
                  } else {
                    that.jierutype = "0";
                  }
                  that.task_typeid = that.xmsublist[0].program[0].task_type;
                  if (that.task_typeid) {
                    that.ptjieruall();
                  }
                } else {
                  that.program = that.xmsublist[0].program[0];
                }
              }
            }
            if (taskpace == 4) {
              if (that.xmsublist[0].technology.length > 0) {
                that.uname_l = that.xmsublist[0].realname;
                that.upic_l = that.xmsublist[0].userpic;
                that.programid = that.xmsublist[0].userid;
                //技术实现
                var legnum = Number(that.xmsublist[0].technology.length) - 1;
                that.technology = that.xmsublist[0].technology[legnum];
                if (legnum == 2) {
                  that.jierutype_jishu = "1";
                } else {
                  that.jierutype_jishu = "0";
                }
                that.task_typeid = that.xmsublist[0].technology[0].task_type;
                if (that.task_typeid) {
                  that.ptjieruall();
                }
              } else {
                that.technology = that.xmsublist[0].technology[0];
                console.log(that.technology);
              }
            }
            if (taskpace == 5) {
              if (that.xmsublist[0].final.length > 0) {
                that.uname_l = that.xmsublist[0].realname;
                that.upic_l = that.xmsublist[0].userpic;
                that.programid = that.xmsublist[0].userid;
                //技术实现
                var legnum = Number(that.xmsublist[0].final.length) - 1;
                that.final = that.xmsublist[0].final[legnum];
                if (legnum == 2) {
                  that.jierutype_cg = "1";
                } else {
                  that.jierutype_cg = "0";
                }
                that.task_typeid = that.xmsublist[0].final[0].task_type;
                if (that.task_typeid) {
                  that.ptjieruall();
                }
              } else {
                that.final = that.xmsublist[0].final[0];
                console.log(that.final);
              }
            }
          } else {
            // that.$message(res.data.messages);
          }
        });
    },
    ptjieruall() {
      var that = this;
      that
        .$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/findInvolved",
          method: "post",
          data: {
            task_id: that.task_id,
            task_type: that.task_typeid,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            if (res.data.result.cause != "") {
              that.ptjierutext = "平台介入记录";
              that.ptjierutext_jishu = "平台介入记录";
              that.ptjierutext_cg = "平台介入记录";
              if (res.data.result.checked == 1) {
                that.jierujinduall[0].cause = res.data.result.result;
              }
              that.jierujinduall.push(res.data.result);
            }
          } else {
            // that.$message(res.data.messages);
          }
        });
    },
    ck_diaoyan(id, userid) {
      this.$router.push({
        path: "/Xiangmu_c_tx",
        query: {
          data: this.task_id,
          r_id: id,
          xmalltitle: this.xmall.title,
          userid: userid,
        },
      });
    },
    uploadzipurl(url) {
      var that = this;
      that.uploadzipurlnum = 2;
      that.uploadzipurls = url;
    },
    uploadzipurl_2(url) {
      var that = this;
      that.uploadzipurlnum = 3;
      that.uploadzipurls = url;
    },
    uploadzipurl_3(url) {
      var that = this;
      that.uploadzipurlnum = 4;
      that.uploadzipurls = url;
    },
    uploadzipurl_4(url) {
      var that = this;
      that.uploadzipurlnum = 5;
      that.uploadzipurls = url;
    },
    funjiansub(id) {
      var that = this;
      that
        .$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/getAddTaskPlan",
          method: "post",
          data: {
            task_id: that.task_id,
            userid: that.userinfo.userid,
            task_type: id,
            tasktext: "空",
            up_file: that.uploadzipurls,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            location.reload();
          } else {
            this.$message({
              message: res.data.messages,
              duration: 1000,
            });
          }
        });
    },
    shenheuser(task_type, checked, uid, taskpacezb) {
      var that = this;
      if (task_type == 3 || (task_type == 4 && checked == 2)) {
        // that.qyjujue = true;
      }
      that
        .$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/getAuditTaskPlan",
          method: "post",
          data: {
            userid: uid,
            task_id: that.task_id,
            task_type,
            reason: that.jujuetext,
            reason_img: "",
            checked,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              message: res.data.messages,
              duration: 1000,
            });

            that.qyjujue = false;
            if (checked == 1) {
              if (taskpacezb == "2") {
                //设置中标设计师
                that
                  .$axios({
                    headers: { "X-CSRF-TOKEN": that.userinfo.token },
                    url: "/api/project/getCancelSetUp",
                    method: "post",
                    data: {
                      task_id: that.task_id,
                      userid: uid,
                      checked: "5",
                    },
                  })
                  .then((res) => {
                    if (res.data.code == 1) {
                      this.$message({
                        message: res.data.messages,
                        duration: 1000,
                      });

                      location.reload();
                    } else {
                      this.$message({
                        message: res.data.messages,
                        duration: 1000,
                      });
                    }
                  });
              }
              location.reload();
            } else {
              location.reload();
            }
          } else {
            this.$message({
              message: res.data.messages,
              duration: 1000,
            });
          }
        });
    },
    chakanjujue() {
      var that = this;
      that
        .$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/refuseList",
          method: "post",
          data: {
            task_id: that.task_id,
            task_type: that.task_typeid,
            userid: that.programid,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            that.activities = res.data.result;
          } else {
            this.$message({
              message: res.data.messages,
              duration: 1000,
            });
          }
        });
    },
    ptjierubtn() {
      var that = this;
      that
        .$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/applyInvolved",
          method: "post",
          data: {
            task_id: that.task_id,
            task_type: that.task_typeid,
            cause: that.jieruliyou,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              message: res.data.messages,
              duration: 1000,
            });

            that.ptjieru = false;
            location.reload();
          } else {
            this.$message({
              message: res.data.messages,
              duration: 1000,
            });
          }
        });
    },
    ptjieruclick(task_type) {
      var that = this;
      if (that.ptjierutext == "平台介入记录") {
        that.jierujindu = true;
      } else {
        that.ptjieru = true;
        that.task_typeid = task_type;
      }
    },
    ptjieruclick_jishu(task_type) {
      var that = this;
      if (that.ptjierutext_jishu == "平台介入记录") {
        that.jierujindu = true;
      } else {
        that.ptjieru = true;
        that.task_typeid = task_type;
      }
    },
    xiangmujinzhantext() {
      var that = this;
      console.log(that.checked_wttype_qy);
      if (that.xmtaskpace == 0) {
        if (that.userinfo != null) {
          if (that.userinfo.groupid == 2) {
            //企业展示
            if (that.undertake == "1") {
              if (that.checked_wttype_qy == "1") {
                that.baomingtext = "待设计师同意委托";
              } else {
                that.baomingtext = "直接委托项目";
              }
            } else {
              that.baomingtext = "报名进行中";
            }
          } else {
            //设计师展示
            if (that.undertake == "1") {
              that.baomingtext = "待设计师同意委托";
            } else {
              that.baomingtext = "报名进行中";
            }
          }
        } else {
          if (that.undertake == "1") {
            that.baomingtext = "直接委托项目";
          } else {
            that.baomingtext = "报名进行中";
          }
        }
      } else if (that.xmtaskpace == 1) {
        that.baomingtext = "调研分析与决策";
      } else if (that.xmtaskpace == 2) {
        that.baomingtext = "初步设计与提案";
      } else if (that.xmtaskpace == 3) {
        that.baomingtext = "方案深化与确立";
      } else if (that.xmtaskpace == 4) {
        that.baomingtext = "技术实现与扩初";
      } else if (that.xmtaskpace == 5) {
        that.baomingtext = "最终成果与交付";
      } else if (that.xmtaskpace == 6) {
        that.baomingtext = "项目结束";
      }
    },
    phoneqingqiu() {
      var that = this;
      that
        .$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/getZbUserXinxi",
          method: "post",
          data: {
            task_id: that.task_id,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            that.phonedata = res.data.result;
            that.qiyephone = true;
          } else {
            this.$message({
              message: res.data.messages,
              duration: 1000,
            });
          }
        });
    },
    baomingshejishi(id) {
      var that = this;
      // 报名设计师列表
      that
        .$axios({
          url: "/api/taskSignList",
          method: "post",
          data: {
            task_id: id,
            page: "1",
            limit: "10",
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            that.count = res.data.result.count;
            for (let a in res.data.result.list) {
              if (res.data.result.list[a].checked == "6") {
                that.checked_wttype_qy = "1";
                ////////////企业展示项目状态
                that.xiangmujinzhantext();
              };
              res.data.result.list[a].skill = res.data.result.list[a].skill.split(",").map((re)=>{
                return that.user_info_iostanbqall.filter(bq=>{
                  re==bq.num
                })
              });
            }
            that.userdata = res.data.result.list;
          } else {
            that.count = 0;
          }
        });
      // 报名设计师列表
    },
  },
  watch: {
    uploadzipurls() {
      var that = this;
      if (this.uploadzipurls != "") {
        that.funjiansub(that.uploadzipurlnum);
      }
    },
    task_typeid() {
      this.chakanjujue();
    },
    programid() {
      this.Sjxingjiall.userid = this.programid;
    },
    activeName() {
      var that = this;
      if (that.activeName == "fivex") {
        if (that.xmtaskpace == 6 || that.xmtaskpace == 5) {
          that.xingjitype = true;
        } else {
          that.xingjitype = false;
        }
      } else {
        that.xingjitype = false;
      }
    },
  },
};
</script>
<style>
@import "../assets/css/xiangmu.css";
</style>