<template>
  <div class="Sjxingji">
    <p class="Sjxingji_title">评价项目-请对本次的项目设计做出评价</p>
    <div class="Sjxingjibox">
      <div class="Sjxingjibox_l">
        <div>
          <span class="Sjxingjibox_lq">工作质量</span>
          <el-rate :disabled="disabledtype" v-model="value1" :colors="colors"></el-rate>
          <span class="Sjxingjibox_lh">{{ value1 }}分</span>
        </div>
        <div>
          <span class="Sjxingjibox_lq">工作速度</span>
          <el-rate :disabled="disabledtype" v-model="value2" :colors="colors"></el-rate>
          <span class="Sjxingjibox_lh">{{ value2 }}分</span>
        </div>
        <div>
          <span class="Sjxingjibox_lq">专业技能</span>
          <el-rate :disabled="disabledtype" v-model="value3" :colors="colors"></el-rate>
          <span class="Sjxingjibox_lh">{{ value3 }}分</span>
        </div>
        <div>
          <span class="Sjxingjibox_lq">工作态度</span>
          <el-rate :disabled="disabledtype" v-model="value4" :colors="colors"></el-rate>
          <span class="Sjxingjibox_lh">{{ value4 }}分</span>
        </div>
      </div>
      <div class="Sjxingjibox_r">
        <p>
          平均分 <span class="Sjxingjibox_rspan">{{ fenall }}分</span>
        </p>
        <el-button :disabled="disabledtype" @click="sub">{{
          subtext
        }}</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["all"],
  components: {},
  data() {
    return {
      colors: ["#99A9BF", "#F7BA2A", "#FF9900"],
      value1: "0",
      value2: "0",
      value3: "0",
      value4: "0",
      fenall: "0",
      userinfo: {},
      subtext: "",
      disabledtype: false,
    };
  },
  methods: {
    zongjs() {
      var a = Number(this.value1);
      var b = Number(this.value2);
      var c = Number(this.value3);
      var d = Number(this.value4);
      this.fenall = (a + b + c + d) / 4;
    },
    sub() {
      var that = this;
      this.$axios({
        headers: {
          "X-CSRF-TOKEN": this.userinfo.token,
        },
        url: "/api/userTaskEvaluate",
        method: "post",
        data: {
          task_id: this.all.task_id,
          userid: this.all.userid,
          taskmost: that.value1,
          taskspeed: that.value2,
          third: that.value3,
          fourth: that.value4,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          this.$message.success("评价成功");
          location.reload();
        } else {
          this.$message.success(res.data.messages);
        }
      });
    },
    xingjiaxios() {
      var that = this;
      that
        .$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/getTaskCommon",
          method: "post",
          data: {
            task_id: that.all.task_id,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            this.value1 =res.data.result.taskmost;
            this.value2 =res.data.result.taskspeed;
            this.value3 =res.data.result.third;
            this.value4 =res.data.result.fourth;
            this.subtext = "已提交";
            this.disabledtype = true;
          } else {
            this.subtext = "立即提交";
            this.disabledtype = false;
          }
        });
    },
  },
  watch: {
    value1() {
      this.zongjs();
    },
    value2() {
      this.zongjs();
    },
    value3() {
      this.zongjs();
    },
    value4() {
      this.zongjs();
    },
  },
  created() {
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
this.tokentimeyz();
    console.log(this.all.task_id);
    this.xingjiaxios();
  },
};
</script>
